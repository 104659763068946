import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appbar: {
    backgroundColor: '#2E394D',
    height: theme.spacing(6),
    borderBottom: '1px solid #495569',
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  homeContainer: {
    padding: theme.spacing(1),
  },
  homeContent: {
    padding: theme.spacing(8, 0, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  successMessage: {
    opacity: 0.85,
    color: theme.palette.common.white,
    transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#111722',
  },
  mesg: {
    textAlign: 'center',
    padding: theme.spacing(0, 0, 2, 0),
  },
}));

export default useStyles;
