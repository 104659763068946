import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AdapterLink from 'sphinx/dist/navigation/AdapterLink';

import getI18nService from '../../i18n/i18n';

const i18n = getI18nService();

const useStyles = makeStyles(theme => ({
  embeded: {
    position: 'absolute',
    zIndex: '10000',
    left: '-15px',
  },
}));

export default function Terms(props) {
  const { text } = props;
  const classes = useStyles();
  const search = new URLSearchParams(window.location.search);
  const embeded = search.get('embeded');
  return (
    <Box
      bgcolor="background.paper"
      className={clsx({ [classes.embeded]: embeded === 'true' })}
      width="100%"
      height="100%"
      m={2}
      p={5}
      display="flex"
      flexDirection="column"
    >
      <Box flexGrow="1">
        <Typography variant="h4" gutterBottom align="center">
          Terms and conditions
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. The formation and content of a contract
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. Authority to enter into these terms with Nile Global
        </Typography>
        <Typography variant="h6" gutterBottom>
          3. License to use
        </Typography>
        <Typography variant="h6" gutterBottom>
          4. License term
        </Typography>
        <Typography>{`embeded ${embeded}`}</Typography>
      </Box>
      {!embeded && (
        <Button
          color="primary"
          size="large"
          component={AdapterLink}
          to="/"
        >
          Back to Home
        </Button>
      )}
    </Box>
  );
}

Terms.propTypes = {
  text: PropTypes.string,
};

Terms.defaultProps = {
  text: null,
};
