import getAxiosXhr, { transformUrl } from 'sphinx/dist/common/xhr';
import { getDescendantProp } from 'sphinx/dist/common/utils/Object';
import getLogger from '../common/Logger';
import mockedEndpoints from '../apis/MockedApis';
import {
  endpointsRequiringTenant,
  endpointsReqSearchWithTenant,
  endpointsReqMaxSize,
} from '../apis';

const cleoConfig = window['CLEO_CONFIG'] || {};
const karnakEnv = window['KARNAK_ENV'];
const mockEndpoint = endpoint =>
  mockedEndpoints.indexOf(endpoint) > -1;

// eslint-disable-next-line no-console
console.log(
  `Following endpoints are using mock data ${mockedEndpoints}`
);

const {
  mockEnv = '',
  addTenantToXhr = 'false',
  apiCacheDuration = 60000,
} = cleoConfig;
const mockDataPrefix = `/mockdata/${mockEnv}/`;
const axiosXhr = getAxiosXhr();

export const addRequest = store => {
  const newStats = { ...store.state.apiStats };
  newStats.requests = newStats.requests + 1;
  store.setState({ apiStats: newStats });
};

export const addSuccess = store => {
  const newStats = { ...store.state.apiStats };
  newStats.requests = newStats.requests + 1;
  store.setState({ apiStats: newStats });
};

export const addFail = store => {
  const newStats = { ...store.state.apiStats };
  newStats.requests = newStats.requests + 1;
  store.setState({ apiStats: newStats });
};

const addCustomHeaders = (api, store) => {
  if (karnakEnv === 'eng') {
    const headers = {};
    const apiKey = store.state.apiKey;
    if (apiKey) {
      headers['x-api-key'] = JSON.stringify(apiKey);
      headers['x-api-host'] = apiKey.serviceUri;
      api.headers = headers;
    }
  }
  return api;
};

function waitForConfirmation(store, message) {
  store.setState({
    confirmationModal: {
      open: true,
      message,
    },
  });
  return new Promise(async (resolve, reject) => {
    const newListener = {
      run: state => {
        if (!state.confirmationModal.open) {
          resolve(state.confirmationModal.confirmed);
          store.listeners = store.listeners.filter(
            lsn => lsn !== newListener
          );
        }
      },
    };
    store.listeners.push(newListener);
  });
}

async function axiosCall(api, store) {
  let response = null;
  if (api.cachable) {
    const data = store.state.apiResponses[api.name];
    if (
      data &&
      Date.now() - store.state.apiTriggerTime[api.name] <
        apiCacheDuration
    ) {
      return { data };
    }
  }
  if (api.cacheFetchPoint) {
    const { service, compareAttr, attr } = api.cacheFetchPoint;
    const data = store.state.apiResponses[service];
    const res = data
      ? data[attr].find(
          cnt => cnt[compareAttr] === api.data[compareAttr]
        )
      : null;
    if (res) {
      return { data: res };
    }
  }
  if (api.expireCacheEndPoints) {
    const expResponses = {};
    api.expireCacheEndPoints.forEach(endpointName => {
      expResponses[endpointName] = null;
    });
    store.setState({
      apiResponses: Object.assign(
        {},
        store.state.apiResponses,
        expResponses
      ),
    });
  }
  transformUrl(api);
  addRequest(store);
  // add custom headers if needed. we add these for dev karnak instance.
  api = addCustomHeaders(api, store);
  if (mockEndpoint(api.name)) {
    // eslint-disable-next-line no-console
    console.log(
      `%cFollowing API executed with mock data: ${api.name}`,
      'color:Red'
    );
    const mockResponse = await axiosXhr(
      mockDataPrefix + api.mockData
    );
    response = { data: mockResponse.data[api.name] || {} };
  } else {
    response = await axiosXhr(api);
    if (
      api.cachable &&
      (api.isRespCachable ? api.isRespCachable(response) : false)
    ) {
      store.setState({
        apiTriggerTime: Object.assign(
          {},
          store.state.apiTriggerTime,
          {
            [api.name]: Date.now(),
          }
        ),
      });
    }
  }
  if (api.createCacheObject) {
    api.createCacheObject(response.data, store);
  }
  // temporary, will remove it later
  if (api.tweekResponseObject) {
    api.tweekResponseObject(response.data);
  }
  addSuccess(store);
  return response;
}

/**
 * Adds tenent aka tanantId to filter if it is not present and is need for backend limitations for now.
 */
const addTenantToQuery = apis => {
  if (!Array.isArray(apis)) {
    apis = [apis];
  }

  const handleApi = api => {
    if (
      endpointsRequiringTenant.indexOf(api.name) > -1 ||
      endpointsReqSearchWithTenant.indexOf(api.name) > -1
    ) {
      // we need to add tenant id to filter.
      getLogger().info(`ORIG query :: ${api.query}`);
      if (addTenantToXhr === 'true' && !api.query) {
        const query = api.query || '';
        if (query.indexOf('tenentId') > -1) {
          // we have it set already so just escape.
          return api;
        }
        const tenantId =
          getDescendantProp(
            window,
            'CLEO_CONFIG.userContext.user.tenantId'
          ) ||
          (api.data && api.data.tenantId);
        // we need to be smart here if we have existing query we shoudl smartly detect it and add it appropriately.
        // for now its okay as it is a temporary arrrangement,
        if (endpointsReqSearchWithTenant.indexOf(api.name) > -1) {
          api.query = `search=tenantId==${tenantId}`;
        } else {
          api.query = `filter=tenantId==${tenantId}`;
        }
        getLogger().warn(
          `Tenant id ${tenantId} was injected to query for lack of RBAC enforcement at backend for call ${api.name}.`
        );
      }
    }
    return api;
  };

  apis.forEach(api => {
    api = handleApi(api);
  });

  return apis;
};

const addMaxSizeToQuery = apis => {
  if (!Array.isArray(apis)) {
    apis = [apis];
  }

  const handleApi = api => {
    if (endpointsReqMaxSize.indexOf(api.name) > -1) {
      // we need to add max size to filter.
      getLogger().info(`ORIG query :: ${api.query}`);
      if (api.query && api.query.indexOf('size') > -1) {
        // we have it set already so just escape.
        return api;
      }
      let { query } = api;
      // we need to be smart here if we have existing query we shoudl smartly detect it and add it appropriately.
      if (query && query.length > 0) {
        query = `${query}&size=2000`;
      } else {
        query = `size=2000`;
      }
      api.query = query;
      getLogger().warn(`Max size injected for ${api.name}.`);
    }
    return api;
  };

  apis.forEach(api => {
    api = handleApi(api);
  });

  return apis;
};

/**
 *
 * @param {object} store store to update.
 * @param {object} api payload supplied for api execution, needs to be what axios config expects.
 */
export const executeApi = async (
  store,
  apiMeta,
  confirmMessage,
  successToaster,
  failToaster,
  refresh
) => {
  let api;
  if (!Array.isArray(apiMeta)) {
    api = Object.assign({}, apiMeta);
  } else {
    api = apiMeta;
  }

  if (confirmMessage || api.confirm) {
    if (
      !(await waitForConfirmation(
        store,
        confirmMessage || api.confirm
      ))
    ) {
      return { confirmed: false };
    }
  }
  let apiStatus = 'LOADING';
  addTenantToQuery(api);
  addMaxSizeToQuery(api);
  store.setState({ apiStatus });
  let responses = [];
  if (!Array.isArray(api)) {
    api = [api];
  }
  const storeObj = {};
  let response;
  try {
    for (let i = 0; i < api.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      response = await axiosCall(api[i], store);
      responses.push(response);
    }
    responses.forEach((resp, i) => {
      const { data, status, statusText } = resp;
      storeObj[api[i].name] = data || { status, statusText };
    });
    store.setState({
      apiResponses: Object.assign(
        {},
        store.state.apiResponses,
        storeObj
      ),
    });
    if (
      successToaster ||
      (api[0].toaster && api[0].toaster.success)
    ) {
      store.setState({
        snackBarOpen: true,
        snackBar: {
          message: successToaster || api[0].toaster.success,
          variant: 'success',
        },
      });
    }
  } catch (error) {
    // eslint-disable-next-line prefer-destructuring
    responses = error.response;
    const isError404 =
      error.response && error.response.status === 404;
    apiStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    storeObj[apiStatus] = apiStatus;
    store.setState(storeObj);
    addFail(store);

    if (failToaster || (api[0].toaster && api[0].toaster.error)) {
      store.setState({
        snackBarOpen: true,
        snackBar: {
          message: failToaster || api[0].toaster.error,
          variant: 'error',
        },
      });
    }
  }
  // eslint-disable-next-line consistent-return
  // let resp;
  // if (Array.isArray(responses) && responses.length === 1) {
  //   resp = responses[0];
  // } else {
  //   resp = responses;
  // }
  // to keep old style await working we'll have to cook up resp.
  let resp;
  const callCount = api.length;
  if (callCount === 1) {
    resp = storeObj[api[0].name];
  } else {
    resp = storeObj;
  }
  return resp;
};

/**
 *
 * @param {object} store store to update.
 * @param {object} api payload supplied for api execution, needs to be what axios config expects.
 */
export const cleanupApi = (store, api) => {
  if (
    store.state.apiResponses &&
    store.state.apiResponses[api.name]
  ) {
    delete store.state.apiResponses[api.name];
  }
};
