import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import AdapterLink from 'sphinx/dist/navigation/AdapterLink';

import useGlobal, { getApiResponseFromStore } from '../../store';
import karnakService from '../../apis/KarnakService';

const options = [
  {
    id: 'signup',
    title: 'Sign up',
    width: '50%',
    to: '/signup',
  },
  {
    id: 'login',
    title: 'Log in',
    width: '50%',
    to: '/login',
  },
];
const karnakEnv = window['KARNAK_ENV'];
if (karnakEnv === 'eng') {
  options.push({
    id: 'localdev',
    title: 'Show config',
    width: '50%',
    to: '/localdev',
  });
}
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '50%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.45,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: `2px solid ${theme.palette.nile.papaya}`,
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    border: '1px solid trensparent',
    '&:hover': {
      border: `1px solid ${theme.palette.nile.papaya}`,
    },
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.nile.bermudaBlue,
    opacity: 0.75,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    fontSize: '1.25rem',
    padding: `${theme.spacing(2)}px ${theme.spacing(
      4
    )}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.nile.papaya,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

export default function Options() {
  const classes = useStyles();
  const [globalState] = useGlobal();

  const bootstrapConfig = getApiResponseFromStore(
    globalState,
    karnakService.GetBootstrap,
    null,
    null
  );

  const handleOnClick = id => event => {
    if (id === 'login') {
      window.location.href = bootstrapConfig.serviceUri;
    }
  };

  return (
    <div className={classes.root}>
      {options.map(option => (
        <ButtonBase
          focusRipple
          color="secondary"
          key={option.title}
          className={classes.image}
          onClick={handleOnClick(option.id)}
          component={AdapterLink}
          to={option.to}
          focusVisibleClassName={classes.focusVisible}
          style={{
            width: option.width,
          }}
        >
          <span className={classes.imageSrc} />
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
              {option.title}
              <span className={classes.imageMarked} />
            </Typography>
          </span>
        </ButtonBase>
      ))}
    </div>
  );
}
