const getEndpointFromStore = (store, endpoint, fallback = {}) => {
  if (store.apiResponses && store.apiResponses[endpoint]) {
    return store.apiResponses[endpoint];
  } else {
    return fallback;
  }
};

/**
 * A list of apis that need to be decorated with tanantId in query so that
 * we only get tenant specific data.
 */
export const endpointsRequiringTenant = [
  'list_sites',
  'list_buildings',
  'list_floors',
  'list_zones',
  'list_deviceLocations',
  'list_devices',
  'list_ssids',
  'list_tags',
];

export const endpointsReqSearchWithTenant = [
  'list_dhcp_servers',
  'list_radius',
];

export const endpointsReqMaxSize = ['list_sites', 'list_buildings'];

export default getEndpointFromStore;
