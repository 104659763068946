import React from 'react';

import globalHook from 'sphinx/dist/common/hooks/globalHook';
import { getDescendantProp } from 'sphinx/dist/common/utils/Object';

import {
  addToCounter,
  executeApi,
  toggleSideBar,
  hideSnackBar,
  hideConfirmationModal,
  showConfirmationModal,
  resetConfirmationModal,
  setCurrentRouteParams,
  setIsFormDirty,
  setRedirectLocation,
  showSnackBar,
  createCacheObject,
  expireCacheObject,
  setUserContext,
  setApiKey,
} from '../actions';

const initialState = {
  counter: 0, // demo stuff will remove later
  sideBarOpen: false, // indicates if the side bar is open or not.
  snackBar: {
    message: 'Some message',
    variant: 'warning',
  },
  confirmationModal: {},
  snackBarOpen: false,
  // api executions.
  apiStats: {
    requests: 0,
    success: 0,
    fail: 0,
  },
  apiStatus: 'INITIAL',
  apiTriggerTime: {},
  apiResponses: {},
  cacheData: {
    tags: [],
    groups: [],
  },
  userContext: {},
  apiKey: null,
};
const actions = {
  addToCounter,
  executeApi,
  toggleSideBar,
  hideSnackBar,
  hideConfirmationModal,
  showConfirmationModal,
  resetConfirmationModal,
  setCurrentRouteParams,
  setIsFormDirty,
  setRedirectLocation,
  showSnackBar,
  createCacheObject,
  expireCacheObject,
  setUserContext,
  setApiKey,
};

const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;

/**
 * @param {*} store The store to search on.
 * @param {*} endpoint api endpoint to llok for, is our standard endpoing defined in corresponding api definitions.
 * @param {*} find ex 'content' will look for key content on an api response. foo.bar.baz with get as json path.
 * @param {*} fallback default to return.
 */
export const getApiResponseFromStore = (
  store,
  endpoint,
  find,
  fallback = {}
) => {
  const { name } = endpoint;
  let result = store.apiResponses[name];
  if (result && find) {
    result = getDescendantProp(result, find);
  }
  return result || fallback;
};

export const getUserContext = store => store.userContext;

export const getTagsFromCache = store => store.cacheData.tags || [];
