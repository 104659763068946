import log from 'loglevel';

const cleoConfig = window ? window['CLEO_CONFIG'] : null;
const defaultModule = 'cleo';
const level = cleoConfig ? cleoConfig.logLevel : log.levels.DEBUG;

const originalFactory = log.methodFactory;
log.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return message => {
    // eslint-disable-next-line no-debugger
    return rawMethod(
      `${defaultModule}: ${message}`,
      'background: #f5f5f5; color: #1769aa;font-size:10px;'
    );
  };
};
log.setLevel(level);
log.setDefaultLevel(level);
const logger = log.getLogger(defaultModule);
/**
 * Get a logger
 * @param {string} loggerName The name of the logger
 * @param {string} logLevel Has to be one of DEBUG, INFO, SILENT =, TRACE, WARN
 * Both params are optional if you want to use the defalut logger
 */
const getLogger = (loggerName, logLevel) => {
  if (!loggerName) {
    return logger;
  }
  const cLog = log.getLogger(loggerName);
  cLog.setLevel(logLevel || level);
  return cLog;
};

export default getLogger;
