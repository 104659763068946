import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  copyrightBackdrop: {
    height: '100%',
    paddingTop: '15px',
    backgroundColor: theme.palette.nile.bermudaBlue,
    opacity: 0.75,
    transition: theme.transitions.create('opacity'),
    color: 'white',
  },
}));

export default function Copyright() {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      align="center"
      className={classes.copyrightBackdrop}
    >
      Copyright © &nbsp;
      <Link color="inherit" href="https://nile-global.cloud">
        Nile Global Inc.
      </Link>
      {` ${new Date().getFullYear()}`}
    </Typography>
  );
}
