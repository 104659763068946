const groupsPrefix = '/api/v1/groups';
const usersPrefix = '/api/v1/users';
const stagedUsersPrefix = '/api/v1/stagedusers';
const mockData = 'IdentityService.json';
const identityService = {
  ListGroups: {
    headers: {},
    method: 'get',
    name: 'list_groups',
    mockData,
    url: groupsPrefix,
    toaster: {
      error: 'Failed to fetch groups list.',
    },
    createCacheObject: (data, store) => {
      const groupsArr = [];
      data.forEach(element => {
        groupsArr.push({
          value: element.id,
          label: element.name,
        });
      });
      store.setState({
        cacheData: Object.assign({}, store.state.cacheData, {
          groups: groupsArr,
        }),
      });
    },
  },
  CreateUser: {
    headers: {},
    method: 'post',
    name: 'post_user',
    url: usersPrefix,
    toaster: {
      // success:
      //   'Successfully signed up the tenant. Please check your email account for instruction on how to proceed.',
      error: 'Failed to signup the user.',
    },
  },
  CreateStagedUser: {
    headers: {},
    method: 'post',
    name: 'post_stageduser',
    url: stagedUsersPrefix,
    toaster: {
      error: 'Failed to signup the user.',
    },
  },
  CheckIfEmailIsRegistered: {
    headers: {},
    method: 'get',
    name: 'checkIfEmailIsRegistered',
    url: `${usersPrefix}/check-email/:email`,
  },
};

export default identityService;
