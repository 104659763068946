import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import getI18nService from '../../i18n/i18n';
import identityService from '../../apis/IdentityService';
import useGlobal from '../../store';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
  },
  container: {
    flexGrow: 1,
    padding: 20,
  },
  textField: {
    width: 925,
    height: 425,
  },
  button: {
    width: 200,
    margin: 10,
  },
}));
const i18n = getI18nService();
export default function AddKey() {
  const classes = useStyles();
  const [globalState, globalActions] = useGlobal();
  const [value, setValue] = React.useState(globalState.apiKey || '');
  const [verified, setVerified] = useState(null);

  const handleChange = event => {
    setValue(event.target.value);
  };

  const saveApiKey = event => {
    globalActions.setApiKey(
      JSON.parse(value.replace(/\r?\n|\r/g, ''))
    );
  };

  const verifyKeyWorks = async event => {
    const groups = await globalActions.executeApi(
      identityService.ListGroups
    );
    setVerified(groups);
  };

  return (
    <Box
      p={2}
      width={1000}
      height={625}
      display="flex"
      className={classes.root}
    >
      <Paper variant="outlined" square className={classes.container}>
        <Box display="flex" flexDirection="column">
          <Typography color="secondary" variant="h6" gutterBottom>
            Add Key
          </Typography>
          <TextField
            className={classes.textField}
            label="Paste an api key and click use this key, verify after it is loaded."
            variant="filled"
            multiline
            value={value}
            onChange={handleChange}
            rows={20}
          />
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={saveApiKey}
            >
              Use this key
            </Button>
            <Button
              variant="contained"
              onClick={verifyKeyWorks}
              className={classes.button}
            >
              Verify key
            </Button>
            <Link
              component={RouterLink}
              to="/signup"
              className={classes.button}
            >
              Go to signup page
            </Link>
            <Link
              component={RouterLink}
              to="/"
              className={classes.button}
            >
              Go to home page
            </Link>
            {verified && (
              <Typography color="secondary" variant="body2">
                {`Successfully verified ${verified.length} groupd found`}
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
