import makeStyles from '@material-ui/core/styles/makeStyles';

export const drawerWidth = 240;
export const minHeight = 64;

const useStyles = makeStyles(theme => ({
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  appBar: {
    backgroundColor: theme.palette.nile.bermudaBlue,
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: `1px solid ${theme.palette.listDevider.default}`,
    boxShadow: theme.shadows[2],
    color: theme.palette.text.primary,
  },
  toobar: {
    minHeight: 56,
  },
  titleLink: {
    fontSize: '2.0rem',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  logo: {
    marginTop: theme.spacing(1.5),
    width: 72,
    height: 37,
  },
}));

export default useStyles;
