import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
// import Header from 'sphinx/dist/navigation/Header';
import ConfirmationModal from 'sphinx/dist/modal/ConfirmationModal';
import getLogger from './common/Logger';
import darkOverrides from './themes/dark';
import lightOverrides from './themes/light';
import HomePageLayout from './layouts/HomePageLayout';
import Copyright from './pages/common/Copyright';
import KarnakSnackBar from './components/common/SnackBar';
import Header from './components/common/Header';
import karnakService from './apis/KarnakService';

import useGlobal, { getApiResponseFromStore } from './store';

const theme = createMuiTheme(lightOverrides);

const footerStyle = {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '50px',
  background: '#212121',
};

const Karnak = () => {
  const [open, setOpen] = React.useState(false);
  const [globalState, globalActions] = useGlobal();

  const bootstrapConfig = getApiResponseFromStore(
    globalState,
    karnakService.GetBootstrap,
    null,
    null
  );

  useEffect(() => {
    globalActions.executeApi(karnakService.GetBootstrap);
  }, []);

  const handleCloseSnackBar = event => {
    globalActions.hideSnackBar();
  };

  const handleCloseConfirmationModal = confirmed => {
    globalActions.hideConfirmationModal(confirmed);
  };

  const handleSidebarToggle = args => {
    getLogger().info(`handleSidebarToggle ${args}`);
  };

  const handleAlerts = args => {
    getLogger().info(`handleSidebarToggle ${args}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Header applicationName="nile" logoUrl="/nile-logo-white.svg" /> */}
      {bootstrapConfig && (
        <HomePageLayout
          config={bootstrapConfig}
          isLeftDrawerOpen={open}
          handleDrawerClose={() => setOpen(false)}
        />
      )}
      <KarnakSnackBar
        open={globalState.snackBarOpen}
        onClose={handleCloseSnackBar}
        message={globalState.snackBar.message}
        variant={globalState.snackBar.variant}
      />
      <ConfirmationModal
        open={globalState.confirmationModal.open}
        onClose={handleCloseConfirmationModal}
        message={globalState.confirmationModal.message}
      />

      <Box style={footerStyle} flexDirection="column">
        <Copyright />
      </Box>
    </ThemeProvider>
  );
};

Karnak.propTypes = {
  // title: PropTypes.string.isRequired,
};

export default Karnak;
