/**
 *
 * @param {object} store Global store
 * @param {number} amount Incement the counter by
 */
export const addToCounter = (store, amount) => {
  const newCounterValue = store.state.counter + amount;
  store.setState({ counter: newCounterValue });
};

/**
 * Toggle the side bar navigation.
 * @param {object} store Global store.
 */
export const toggleSideBar = store => {
  store.setState({ sideBarOpen: !store.state.sideBarOpen });
};

/**
 * Set confirmation modal
 * @param {object} store Global store.
 */
export const hideConfirmationModal = (store, confirmed) => {
  store.setState({
    confirmationModal: {
      open: false,
      confirmed,
    },
  });
};

export const resetConfirmationModal = store => {
  store.setState({
    confirmationModal: {
      open: false,
    },
  });
};

/**
 * Set confirmation modal
 * @param {object} store Global store.
 */
export const showConfirmationModal = (store, message) => {
  store.setState({
    confirmationModal: {
      open: true,
      message,
      confirmed: false,
    },
  });
};

/**
 * Hide the side bar navigation.
 * @param {object} store Global store.
 */
export const hideSnackBar = store => {
  store.setState({ snackBarOpen: false });
};

/**
 * Hide the side bar navigation.
 * @param {object} store Global store.
 * @param {object} snackBar Refer global store for snackBar details.
 */
export const showSnackBar = (store, snackBar) => {
  store.setState({ snackBarOpen: true, snackBar });
};

/**
 * Temporarily store the logged in user context.
 * @param {object} store state
 * @param {object} userContext object
 */
export const setUserContext = (store, context) => {
  const newContext = Object.assign(
    {},
    store.state.userContext,
    context
  );
  // Added userContext to global as we need it for some api calls.
  window.CLEO_CONFIG['userContext'] = newContext;
  store.setState({
    userContext: newContext,
  });
};

/**
 * Set form dirty
 * @param {object} store state
 * @param {object} isFormDirty boolean
 */
export const setIsFormDirty = (store, isFormDirty) => {
  store.setState({
    isFormDirty,
  });
};

/**
 * Set redirect url
 * @param {object} store state
 * @param {object} redirectLocation object
 */
export const setRedirectLocation = (store, redirectLocation) => {
  store.setState({
    redirectLocation,
  });
};

/**
 * Set current route params
 * @param {object} store state
 * @param {object} routeParams object
 */
export const setCurrentRouteParams = (store, routeParams) => {
  store.setState({
    routeParams,
  });
};

/**
 * Temporarily store the logged in user context.
 * @param {object} store state
 * @param {object} userContext object
 */
export const setApiKey = (store, apiKey) => {
  store.setState({
    apiKey,
  });
};
