import { makeStyles } from '@material-ui/core/styles';
import { FlareSharp } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: 700,
    width: 600,
    opacity: 0.8,
    padding: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    padding: theme.spacing(0, 4),
  },
  bannerLeft: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.nile.bermudaBlue,
    borderTopLeftRadius: theme.spacing(4),
    borderBottomLeftRadius: theme.spacing(4),
    padding: theme.spacing(4, 2),
    textAlign: 'center',
  },
  gridContainer: {
    height: '100%',
  },
  fixedHeightRow: {
    height: 40,
  },
  buttons: {
    borderTop: `1px solid ${theme.palette.grey[700]}`,
  },
  button: {
    margin: theme.spacing(0, 5),
    borderRadius: theme.spacing(3),
    transform: 'translate(0%,50%)',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.nile.papaya,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  captcha: {
    paddingTop: theme.spacing(8),
  },
  textField: {
    marginTop: theme.spacing(4),
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: theme.spacing(3),
    },
  },
  textFieldOne: {
    marginTop: theme.spacing(2),
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: theme.spacing(3),
    },
  },
  searchInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: theme.spacing(3),
    },
  },
  signIn: {
    cursor: 'pointer',
  },
  createBtn: {
    backgroundColor: theme.palette.primary.main,
  },
  countryCode: {
    padding: theme.spacing(5, 0, 2, 0),
  },
  searchPlaces: {
    padding: theme.spacing(4, 0, 2, 0),
  },
  privacyLabel: {
    display: 'flex',
    flexDirection: 'row',
  },
  privacyLink: {
    marginLeft: theme.spacing(1),
  },
  nileName: {
    padding: theme.spacing(0, 1),
    fontWeight: 600,
  },
}));

export default useStyles;
