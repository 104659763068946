/**
 * Create cache object.
 * @param {object} store Global store.
 */
export const createCacheObject = (store, cacheObj) => {
  store.setState({
    cacheData: Object.assign({}, store.state.cacheData, cacheObj),
  });
};

/**
 * Expire cache object
 * @param {object} store Global store.
 * @param {object} snackBar Refer global store for snackBar details.
 */
export const expireCacheObject = (store, cacheName) => {
  delete store.state.cacheData[cacheName];
};
