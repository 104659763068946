import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import getLogger from '../../common/Logger';

import useStyles from './SnackBar.jss';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const KarnakSnackBar = props => {
  const {
    anchorOrigin,
    duration,
    message,
    open,
    onClose,
    variant,
    ...other
  } = props;
  const classes = useStyles();

  const Icon = variantIcon[variant];
  const messageComponent = (
    <span id="client-snackbar" className={classes.message}>
      <Icon className={clsx(classes.icon, classes.iconVariant)} />
      {message}
    </span>
  );

  let autoHideDuration = duration;

  if (!autoHideDuration && variant === 'success') {
    autoHideDuration = 3000;
  }

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{message}</span>}
      variant={variant}
      {...other}
    >
      <SnackbarContent
        className={clsx(classes[variant])}
        aria-describedby="client-snackbar"
        message={messageComponent}
        action={[
          <IconButton
            onClick={onClose}
            key="close"
            aria-label="close"
            color="inherit"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

KarnakSnackBar.propTypes = {
  anchorOrigin: PropTypes.object,
  duration: PropTypes.number,
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

KarnakSnackBar.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  duration: 5000,
  message: '',
  onClose: event => getLogger().info('close snackbar called!'),
  open: false,
  variant: 'info',
};

export default KarnakSnackBar;
