import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
// import { Document, Page } from 'react-pdf//dist/umd/entry.webpack';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import getI18nService from '../../i18n/i18n';
// import { privacy } from './Privacy.md';

// setOptions({
//   workerSrc: '/js/pdf.worker.js',
// });

const i18n = getI18nService();

const useStyles = makeStyles(theme => ({
  embeded: {
    position: 'absolute',
    zIndex: '10000',
  },
}));

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};
export default function Privacy(props) {
  const { file } = props;
  const classes = useStyles();
  const search = new URLSearchParams(window.location.search);
  const embeded = search.get('embeded');
  const [numPages, setNumPages] = useState(1);
  // const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = doc => {
    setNumPages(doc.numPages);
  };

  return (
    <Box
      bgcolor="background.paper"
      className={clsx({ [classes.embeded]: embeded === 'true' })}
      alignItems="center"
      width="100%"
      height="100%"
      p={1}
      display="flex"
      flexDirection="column"
    >
      {/* <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document> */}
    </Box>
  );
}

Privacy.propTypes = {
  file: PropTypes.string,
};

Privacy.defaultProps = {
  file: '/content/NileGlobalPrivacyPolicy.pdf',
};
