import Polyglot from 'node-polyglot';

import phrases from './en-US/phrases';

const phrasesFR = require('../i18n/fr-FR/phrases').default;
const phrasesAR = require('../i18n/ar-AR/phrases').default;

let locale = 'en_US';

let phrasesToUse = phrases;

const cleoLocale = localStorage.getItem('cleo-locale');
if (cleoLocale && cleoLocale === 'fr-FR') {
  locale = 'fr-FR';
  phrasesToUse = Object.assign(phrasesToUse, phrasesFR);
}
if (cleoLocale && cleoLocale === 'ar-AR') {
  locale = 'ar-AR';
  phrasesToUse = Object.assign(phrasesToUse, phrasesAR);
}

// if (localStorage.getItem('localeChecked') === 'false') {
//   locale = 'fr-FR';
//   phrasesToUse = phrasesFR;
// }

const polyglot = new Polyglot({
  locale,
  phrases: phrasesToUse,
});

const getI18nService = () => {
  return polyglot;
};

export default getI18nService;
