import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import AdapterLink from 'sphinx/dist/navigation/AdapterLink';
import Paper from '@material-ui/core/Paper';

import getI18nService from '../../i18n/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(10, 10),
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(10, 10),
  },
}));

const i18n = getI18nService();

export default function PageNotFound(props) {
  const { text } = props;
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h5" component="h3" gutterBottom>
          {text || i18n.t('Vocabulary.pageNotFound')}
        </Typography>
        <Button
          color="primary"
          size="large"
          component={AdapterLink}
          to="/"
        >
          Back to Home
        </Button>
      </Paper>
    </Container>
  );
}

PageNotFound.propTypes = {
  text: PropTypes.string,
};

PageNotFound.defaultProps = {
  text: null,
};
