import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const isValidPhoneNumber = phoneNumber => {
  let valid = false;
  try {
    valid = parsePhoneNumberFromString(phoneNumber).isValid();
  } catch (e) {
    valid = false;
  }

  return valid;
};

export const getFormatedPhoneNumber = phoneNumber => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);
  return parsedNumber.formatInternational();
};

export const getFormatNational = phoneNumber => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);
  return parsedNumber.formatNational();
};

export const getParsedPhoneNumber = phoneNumber => {
  try {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);

    return [
      parsedNumber.countryCallingCode,
      parsedNumber.nationalNumber,
    ];
  } catch (ex) {
    return [1, phoneNumber];
  }
};

export const isValidSsidConfigMap = configMap => {
  try {
    let res = true;
    Object.keys(configMap).forEach(scope => {
      configMap[scope].forEach(sett => {
        if (
          !sett.id ||
          (sett.type === 'DHCP_CLIENT' &&
            !(sett.extra && sett.extra.length > 0))
        ) {
          res = false;
        }
      });
    });
    return res;
  } catch (ex) {
    return false;
  }
};
