import React from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import Animation from '../../components/animation/Animation';
import getI18nService from '../../i18n/i18n';
import Options from './Options';
import useStyles from './Home.jss';

const i18n = getI18nService();

export default function Home() {
  const classes = useStyles();
  const location = useLocation();
  const showMessage =
    location.search && location.search.indexOf('signupSuccess') > -1;
  const getMesg = () => {
    const urlParams = new URLSearchParams(location.search);
    const signupSuccess = urlParams.get('signupSuccess');
    if (signupSuccess === 'true') {
      return i18n.t('Signup.signupSuccess');
    } else if (signupSuccess === 'false') {
      return i18n.t('Signup.signupFailed');
    }
    return '';
  };
  const getSpamMesg = () => {
    const urlParams = new URLSearchParams(location.search);
    const signupSuccess = urlParams.get('signupSuccess');
    if (signupSuccess === 'true') {
      return `Note: ${i18n.t('Signup.checkSpamFolderMessage')}`;
    }
    return '';
  };

  return (
    <React.Fragment>
      <Container component="div" className={classes.homeContent}>
        {showMessage && (
          <Box
            className={classes.successMessage}
            boxShadow={1}
            p={4}
            m={4}
          >
            <Typography variant="h6">{getMesg()}</Typography>
            <Typography variant="body2" className={classes.mesg}>
              {getSpamMesg()}
            </Typography>
          </Box>
        )}
        <Options />
      </Container>
      {/* <Animation /> */}
    </React.Fragment>
  );
}
