const phrases = {
  Vocabulary: {
    beingBuilt: 'هذه الميزة قيد العمل ، يرجى زيارة الموقع لاحقًا.',
    configuration: 'التكوين',
    customers: 'العملاء',
    dashboard: 'لوحة القيادة',
    demo: 'تجريبي',
    monitor: 'مراقب',
    or: 'مرا',
    partners: 'الشركاء',
    search: 'بحث',
    support: 'دعم',
    tenant: 'المستأجر',
    tenants: 'المستأجر',
    tool: 'الأداة',
    tools: 'الأداة',
    typeToFilter: 'Type to filter...',
    update: 'تحديث',
  },
};

export default phrases;
