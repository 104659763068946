import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import Karnak from './Karnak';

const title = 'Karnak from Nile';
ReactDOM.render(
  <BrowserRouter>
    <Karnak title={title} />
  </BrowserRouter>,
  document.getElementById('karnak')
);

if (module.hot) {
  module.hot.accept();
}
