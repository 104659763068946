import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Signup from '../pages/signup/Signup';
import Home from '../pages/home/Home';
import Login from '../pages/home/Login';
import AddKey from '../pages/local/AddKey';
import PageNotFound from '../pages/common/PageNotFound';
import Terms from '../pages/terms/Terms';
import Privacy from '../pages/terms/Privacy';
import useGlobal from '../store';
import getI18nService from '../i18n/i18n';

const i18n = getI18nService();

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: `calc(100vh - 80px)`,
    paddingBottom: '50px',
  },
}));

let unBlockHistory = null;

const karnakEnv = window['KARNAK_ENV'];
const HomePageLayout = ({
  config,
  isLeftDrawerOpen,
  handleDrawerClose,
}) => {
  const classes = useStyles();
  const [globalState, globalActions] = useGlobal();
  // const [navItems, setNavItems] = useState([]);
  const serviceUri = config ? config.serviceUri : '';
  const {
    isFormDirty,
    redirectLocation,
    confirmationModal,
  } = globalState;
  // Shows up confirmation message whenever use leaves page with unsaved form changes
  const history = useHistory();
  if (unBlockHistory) {
    unBlockHistory();
  }
  unBlockHistory = history.block((location, action) => {
    if (
      !isFormDirty &&
      window.location.pathname === location.pathname
    ) {
      return true;
    } else if (confirmationModal.confirmed && redirectLocation) {
      globalActions.setRedirectLocation(null);
      globalActions.setIsFormDirty(false);
      globalActions.resetConfirmationModal();
      return true;
    } else if (isFormDirty) {
      globalActions.setRedirectLocation(location);
      globalActions.showConfirmationModal(
        i18n.t('Messages.unSavedChanges')
      );
      return false;
    }
    return true;
  });

  if (confirmationModal.confirmed && redirectLocation) {
    history.push(redirectLocation.pathname);
  }
  useEffect(() => {
    if (isFormDirty) {
      window.onbeforeunload = url => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [isFormDirty]);

  return (
    <Box
      height="100%"
      flexGrow="1"
      className={classes.root}
      display="flex"
      overflow="visible"
      alignItems="center"
      data-id="karnakMainContainer"
      justifyContent="center"
    >
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy" component={Privacy} />
        {karnakEnv && karnakEnv === 'eng' && (
          <Route exact path="/localdev" component={AddKey} />
        )}
        <Route
          exact
          path="/login"
          render={() => <Login serviceUrl={serviceUri} />}
        />
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </Box>
  );
};

HomePageLayout.propTypes = {
  config: PropTypes.object.isRequired,
  isLeftDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

export default HomePageLayout;
