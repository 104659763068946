const karnakService = {
  GetBootstrap: {
    headers: {},
    method: 'get',
    name: 'get_bootstrap',
    url: 'bootstrap',
  },
  PostSlack: {
    headers: {},
    method: 'post',
    name: 'post_slack',
    url: 'slack',
  },
  verifyemail: {
    headers: {},
    method: 'get',
    name: 'get_verifyemail',
    url: 'verifyemail',
  },
  verifyCaptcha: {
    headers: {},
    method: 'post',
    name: 'post_verify_captcha',
    url: 'verifyCaptcha',
  },
  signup: {
    headers: {},
    method: 'post',
    name: 'post_signup',
    url: 'api/v1/signup',
  },
  CheckIfEmailIsRegistered: {
    headers: {},
    method: 'get',
    name: 'checkIfEmailIsRegistered',
    url: 'api/v1/users/check-email/:email',
  },
};

export default karnakService;
