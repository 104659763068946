import getLogger from '../common/Logger';

const mockedEndpoints = [];

const services = {
  'metric-broker': [
    'mb_list_tenants',
    'mb_list_devices',
    'mb_get_device',
    'mb_get_device_ssids',
  ],
  tenantservice: [
    'list_addresses',
    'get_tenants',
    'get_tenant',
    'list_tags',
    'post_tags',
    'put_tags',
  ],
  'identitymgmt-service': [
    'get_group',
    'list_groups',
    'get_user',
    'list_users',
    'get_userinfo',
  ],
  'site-manager': [
    'get_region',
    'list_regions',
    'list_sites',
    'get_site',
    'list_buildings',
    'get_building',
    'list_floors',
    'get_floor',
    'list_zones',
    'list_deviceLocations',
    'get_deviceLocation',
  ],
  shadowdevice: ['list_devices', 'get_device'],
  availability: ['get_tenant_avilability', 'get_device_avilability'],
  clientservice: [
    'get_client_info',
    'get_client_metric',
    'get_client_metrics',
    'get_client_list_details',
    'get_client_summary',
  ],
  'sensor-metrics': [
    'get_wifi_capacity',
    'get_wifi_capacity_violations',
    'get_service_availability_violations',
    'get_app_aval_summary',
    'get_wifi_coverage_violations',
    // 'get_sensor_app_exp',
  ],
  configurationservice: [
    'list_dhcp_servers',
    'delete_dhcp_servers',
    'list_radius',
    'list_ssids',
    'get_ssid',
  ],
  tokenService: ['post_keys'],
};

if (window['KARNAK_ENV'] && window['KARNAK_ENV'] === 'dev') {
  if (window['MOCKED_SERVCES']) {
    window['MOCKED_SERVCES'].split(',').forEach(service => {
      if (services[service]) {
        Array.prototype.push.apply(
          mockedEndpoints,
          services[service]
        );
      }
    });
  }
}

getLogger().info(
  `%cFollowing APIs are being mocked : ${mockedEndpoints.join(', ')}`,
  'color:Red'
);

export default mockedEndpoints;
