import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

import getI18nService from '../../i18n/i18n';

const i18n = getI18nService();

const Login = props => {
  return (
    <Box
      height="50%"
      flexGrow="1"
      display="flex"
      overflow="visible"
      mx="auto"
      p={5}
      alignItems="center"
    >
      <Typography variant="h5" color="inherit">
        {i18n.t('Vocabulary.redirectToLogin')}
      </Typography>
    </Box>
  );
};

// Login.propTypes = {
//   serviceUri: PropTypes.string.isRequired,
// };

export default Login;
