const phrases = {
  Vocabulary: {
    beingBuilt:
      'This feature is being worked on, please visit sometime later.',
    cancel: 'Cancel',
    company: 'Company',
    delete: 'Delete',
    edit: 'Edit',
    email: 'Email',
    fieldRequired: 'This is a required field.',
    firstName: 'First Name',
    fullName: 'Full Name',
    general: 'General',
    geoFilter: 'Geo filter',
    geoScope: 'Geo scope',
    groups: 'Groups',
    hardware: 'Hardware',
    infrastructure: 'Infrastructure',
    ipAddress: 'IP address',
    iqi: 'IQI',
    lastName: 'Last Name',
    modify: 'Modify',
    monitor: 'Monitor',
    name: 'Name',
    none: 'None',
    or: 'OR',
    pageNotFound: 'Requested page or action does not exist',
    phone: 'Phone',
    redirectToLogin: 'Redirect to login ...',
    requiredField: 'This is a required field',
    save: 'Save',
    search: 'Search',
    shouldBeNumber: 'Should be number',
    signup: 'Signup',
    support: 'Support',
    typeToFilter: 'Type to filter...',
    unknown: 'Unknown',
  },
  Address: {
    address: 'Address',
    city: 'City',
    country: 'Country',
    floorNumber: 'Floor Number',
    selectCountry: 'Select Country',
    state: 'State',
    streetAddress: 'Street Address',
    street: 'Street',
    region: 'Region',
    zip: 'Zip Code',
  },
  Signup: {
    accountSignup: 'Account signup',
    createAccount: 'Create account',
    checkSpamFolderMessage:
      'Email containing setup instructions may sometimes go to your spam folder!',
    emailAlreadyRegistered: 'This email is already registered',
    emailCorporateHelpText:
      'Please use a corporate account to register',
    emailRegisteredAndNotCorporate:
      'This email is alredy registered or is not a coporate account',
    eulaText: 'Your use of this account is subject to the ',
    eulaLinkText: 'Nile Supplemental End Customer Agreement',
    nileMessage: 'Connect people, devices and applications',
    tcText: `I agree to Nile Global Inc's `,
    phoneHelp: 'Primary Phone Number',
    tenantHeader: 'Tenant Information',
    clearAll: 'CLEAR ALL',
    emailHelper: 'For corporate users only',
    privacyPolicies: 'privacy policies',
    service: 'service',
    signupTitle: 'Sign up for Nile service',
    regionHelper:
      'Information we collect about the service will be hosted in this region',
    signIn: 'SIGN IN',
    signupSuccess:
      'You have successfully signed up with Nile, please check your email and setup your credentials.',
    signupFailed: 'Signup failed please try again later.',
  },
  Messages: {
    incorrectFormat: 'Incorrect format',
    required: 'This field is required',
    unSavedChanges:
      'There are unsaved changes, Are you sure you want to navigate away from this page?',
  },
};

export default phrases;
