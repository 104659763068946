import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import useStyles from './Header.jss';

export default function Header(props) {
  const { applicationName, logoUrl } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        color="default"
        className={classes.appBar}
      >
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            <Link
              className={classes.titleLink}
              component={RouterLink}
              to="/"
            >
              <img
                src={logoUrl}
                className={classes.logo}
                alt={applicationName}
              />
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  applicationName: PropTypes.string,
  logoUrl: PropTypes.string,
};

Header.defaultProps = {
  applicationName: 'Nile',
  logoUrl: '/static/nile-logo-white.svg',
};
